document.addEventListener("DOMContentLoaded", function () {
    const items = document.querySelectorAll('.gallery__item'); 
    const loadMoreBtn = document.querySelector('.gallery__btn'); 
    let shown = 6; 
    const increment = 6; 

    function showItems() {
        for (let i = 0; i < shown && i < items.length; i++) {
            items[i].style.display = 'block'; 
        }

        if (shown >= items.length) {
            loadMoreBtn.disabled = true;
            loadMoreBtn.textContent = "Все элементы показаны";
        }
    }

    loadMoreBtn.addEventListener('click', function () {
        shown += increment;
        showItems();
    });

    showItems();
});


document.addEventListener('DOMContentLoaded', function () {
    const btn = document.querySelector('.translate__btn');
    const lang = document.querySelector('.translate__lang');
    const glink = document.querySelectorAll('.glink');

    if (btn) {
        btn.addEventListener('click', function (event) {
            event.stopPropagation(); 
            btn.classList.toggle('active');
            
            if (lang.style.display === 'none' || lang.style.display === '') {
                lang.style.display = 'flex'; 

                setTimeout(() => {
                    lang.classList.toggle('active');
                }, 200); 
            } else {
                lang.classList.remove('active');
                lang.style.display = 'none'; 
            }
        });
    }

    glink.forEach(link => {
        link.addEventListener('click', function (event) {
            event.stopPropagation();
            btn.classList.remove('active');
            lang.classList.remove('active');
            lang.style.display = 'none'; 
        });
    });

    document.addEventListener('click', function () {
        btn.classList.remove('active');
        lang.classList.remove('active');
        lang.style.display = 'none'; 
    });
});

