document.addEventListener('DOMContentLoaded', function() {
    const dropdowns = document.querySelectorAll('.slider__dropdown');

    dropdowns.forEach(dropdown => {
        const button = dropdown.closest('.slider__card').querySelector('.btn-three'); 
        if (dropdown.scrollHeight < 120) {
            button.style.display = 'none'; 
        } else {
            button.style.display = 'inline-flex';
        }
    });

    const buttons = document.querySelectorAll('.btn-three');

    buttons.forEach(button => {
        button.addEventListener('click', function() {
            const dropdown = this.closest('.slider__card').querySelector('.slider__dropdown');
            if (dropdown && dropdown.classList.contains('slider__dropdown')) {
                if (!dropdown.classList.contains('open')) {
                    this.classList.add('open');
                    dropdown.classList.add('open');
                    dropdown.style.maxHeight = dropdown.scrollHeight + 'px'; 
                } else {
                    dropdown.style.maxHeight = '120px'; 

                    setTimeout(() => {
                        dropdown.classList.remove('open'); 
                        this.classList.remove('open'); 
                    }, 300);
                }
            }
        });
    });
});







