document.addEventListener('DOMContentLoaded', function() {
    const sections = document.querySelectorAll('section');
    
    sections.forEach((section, index) => {
        section.setAttribute('id', `section-${index}`);
        
        if (index === 1) {
            section.classList.add('first-block');
        }
    });
});
