import Swiper from 'swiper';
import { Navigation, Scrollbar, Thumbs, Pagination, FreeMode  } from 'swiper/modules';

var swiper = new Swiper(".Type-2", {
    modules: [Navigation,Pagination],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        600: {
            slidesPerView: 2,
        },
        1025: {
            slidesPerView: 3,
        }
    }
  });

  function updatePagination(swiper) {
    const pagination = swiper.pagination.el;
    const currentIndex = swiper.realIndex + 1;
    const formattedIndex = currentIndex < 10 ? `0${currentIndex}` : currentIndex;
    pagination.textContent = formattedIndex; 
}


var swiper = new Swiper(".Banner", {
  modules: [Navigation,Pagination],
  loop: true,
  slidesPerView: 1,
  pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
  navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
});


var swiper = new Swiper(".Assortment", {
  modules: [Navigation,Pagination],
  loop: false,
  slidesPerView: 1.4,
  spaceBetween: 10,
  FreeMode: true,
  pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
  navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      700: {
        slidesPerView: 3,
      },
      880: {
          slidesPerView: 4,
      },
      1300: {
          slidesPerView: 5,
      }
  }
});


var swiper = new Swiper(".SEO-slider", {
  modules: [Navigation,Pagination],
  loop: true,
  slidesPerView: 1,
  spaceBetween: 30,
  pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
  navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
});