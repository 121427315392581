document.addEventListener('DOMContentLoaded', function () {
    const menuToggle = document.getElementById('menu-toggle');
    if (menuToggle) {
        menuToggle.addEventListener('click', function() {
            this.classList.toggle('active');
    
            const mobileMenu = document.getElementById('mobile-menu');
            const header = document.querySelector('.header');
    
            if (mobileMenu) {
                mobileMenu.classList.toggle('active');
            }
    
            if (header) {
                header.classList.toggle('active'); 
            }
        });
    }
});

document.addEventListener('DOMContentLoaded', function() {
    const bannerNoImg = document.querySelector('.non-img');
    const header = document.querySelector('.header');

    if (bannerNoImg && header) {
        header.classList.add('not-found');
    }
});


document.addEventListener('DOMContentLoaded', function () {
    const btns = document.querySelectorAll('.menu-item-has-children');

    btns.forEach(btn => {
        btn.addEventListener('click', function (event) {
            const child = this.querySelector('.sub-menu');

            if (child && !event.target.closest('.sub-menu')) {
                this.classList.toggle('active');
                child.classList.toggle('active');
            }
        });
    });
    
    document.addEventListener('click', function (event) {
        if (!event.target.closest('.menu-item-has-children')) {
            btns.forEach(btn => {
                const child = btn.querySelector('.sub-menu');
                if (child && child.classList.contains('active')) {
                    btn.classList.remove('active');
                    child.classList.remove('active');
                }
            });
        }
    });
});
