import "./main.scss";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import "htmx.org";

import "./js/index/mobile";
import "./js/index/scroll";
import "./js/index/swiper";
import "./js/index/stroka";
import "./js/index/dropdown";
import "./js/index/section";

import "./js/alpine";
import "./js/modal";